import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const About = () => (
  <Container className="about">
    <Row className="justify-content-md-center">
      <Col md={10}>
        <h2>Our templates have been custom built by our team of Graphic Designers & Director's Assistant's to suit your project in all stages of the production process.</h2>
      </Col>
    </Row>
  </Container>
);

export default About;
