import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Divider from '../components/divider';
import Banner from '../components/banner';
import About from '../components/about';
import Layups from '../components/layups';
import Pricing from '../components/pricing';
import Contact from '../components/contact';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Banner />
    <Divider />
    <About />
    <Divider />
    <Layups />
    <Divider />
    <Pricing />
    <Divider />
    <Contact />
    <Divider />
  </Layout>
)

export default IndexPage;
