import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Layups = ({ data }) => (
  <div className="border-container">
    <Container className="layups">
      <Row>
        <Col sm={12} md={6} lg={3}>
          <Card className="layups-card">
            <div className="card-icon">
              <Img
                alt="Light Bulb Icon"
                fixed={data.lightBulbIcon.childImageSharp.fixed}
              />
            </div>
            <Card.Body>
              <Card.Title>Creative Pitch</Card.Title>
              <Card.Text>
                Whether it's pitching a TVC, TV series idea/ Film idea, pitching for new business, or just needing a creative layup for your concept, using the Little Layup will make it pitch perfect!
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Card className="layups-card">
            <div className="card-icon">
              <Img
                alt="Director Icon"
                fixed={data.directorsIcon.childImageSharp.fixed}
              />
            </div>
            <Card.Body>
              <Card.Title>Directors Treatment</Card.Title>
              <Card.Text>
                Let us take off some of the load (and budget) of the strenuous TVC bidding process by laying up your treatment into one of our fresh new designs to help you stand out from the competition.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Card className="layups-card">
            <div className="card-icon">
              <Img
                alt="PPM Icon"
                fixed={data.layupIcon.childImageSharp.fixed}
              />
            </div>
            <Card.Body>
              <Card.Title>PPM Document</Card.Title>
              <Card.Text>
                You get us the agenda, copy and images and we'll lay them up with our clean and sleek PPM designs. All of your information will be easily numbered to share on with your suppliers. 
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={6} lg={3}>
          <Card className="layups-card">
            <div className="card-icon">
              <Img
                alt="Production Icon"
                fixed={data.productionIcon.childImageSharp.fixed}
              />
            </div>
            <Card.Body>
              <Card.Title>Document Layups</Card.Title>
              <Card.Text>
                Whether it's Storyboards, Scripts, Casting, Locations, Wardrobe or Props we can work directly with your team, director and suppliers to layup any element of pre-production.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={8} className="text-center">
          <p>Download one of our templates now or simply let us know what you need, we will jump on Skype for a short briefing and work within your schedule. No turn around is too tight!</p>
          <div className="btn-container center">
            <Button variant="outline-primary" href="m&#97;ilt&#111;&#58;%74&#104;e%6Ci&#116;&#116;le&#108;a%79up&#64;%67m%61&#105;&#108;&#46;c%6F&#37;6D">
              Contact Us
            </Button>
            <Img
              alt="Button Shadow"
              fixed={data.buttonShadowImg.childImageSharp.fixed}
              className="btn-shadow"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        lightBulbIcon: file(relativePath: { eq: "light-bulb-icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 38, height: 47) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        directorsIcon: file(relativePath: { eq: "director-icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 50, height: 49) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        layupIcon: file(relativePath: { eq: "layup-icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 47, height: 28) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        productionIcon: file(relativePath: { eq: "production-icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 37, height: 48) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        buttonShadowImg: file(relativePath: { eq: "button-shadow.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 167, height: 41) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <Layups data={data} />}
  />
);
