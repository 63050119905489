import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AnchorLink from 'react-anchor-link-smooth-scroll';

const Banner = ({ data }) => (
  <div className="border-banner-container">
    <Container className="banner">
      <Row>
        <Col sm={12} md={12} lg={5}>
          <div className="banner-container">
            <h1>The Little Layup</h1>
            <p>Beautiful, easy and budget friendly templates for the Film, TV & TVC industry.</p>
              <div className="btn-container">
                <AnchorLink
                  href="#pricing"
                  data-rb-event-key="#pricing"
                  className="btn btn-outline-primary"
                >
                  Pricing
                </AnchorLink>
                <Img
                  alt="Button Shadow"
                  fixed={data.buttonShadowImg.childImageSharp.fixed}
                  className="btn-shadow"
                />
              </div>
          </div>
        </Col>
        <Col lg={{ span: 6, offset: 1 }} className="d-none d-lg-block">
          <div className="banner-img-container"> 
            <Img
              alt="Banner Image"
              fluid={data.bannerImg.childImageSharp.fluid}
              className="banner-img"
            />
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        bannerImg: file(relativePath: { eq: "banner.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 464) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        buttonShadowImg: file(relativePath: { eq: "button-shadow.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 167, height: 41) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <Banner data={data} />}
  />
);

Banner.propTypes = {
  data: PropTypes.shape({
    bannerImg: PropTypes.object.isRequired,
  }).isRequired,
};
