import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const PricingCard = ({
  data,
  title,
  price,
  subtitle,
  listItem,
  buttonText,
  buttonHref,
}) => (
  <Card className="pricing-card">
    <Card.Body>
      <Card.Title>{title}</Card.Title>
      <h4 className="price">{price}</h4>
      <div className="subtitle">{subtitle}</div>
      <ul className="list-item">
        {listItem.map((v, i) => {
          return(
            <li key={i}>
              <Img
                alt="Check Icon"
                fixed={data.checkIcon.childImageSharp.fixed}
                className="check-icon"
              />
              {v}
            </li>
          )
        })}
      </ul>
      <Button variant="outline-primary" href={buttonHref ? buttonHref : '#'}>
        {buttonText}
      </Button>
    </Card.Body>
    <Img
      alt="Card Shadow"
      fixed={data.cardShadow.childImageSharp.fixed}
      className="card-shadow-img"
    />
  </Card>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        cardShadow: file(relativePath: { eq: "card-shadow.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 288, height: 348) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        checkIcon: file(relativePath: { eq: "check-icon.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 14, height: 11) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <PricingCard data={data} {...props} />}
  />
);

PricingCard.propTypes = {
  data: PropTypes.shape({
    cardShadow: PropTypes.object.isRequired,
  }).isRequired,
  title: PropTypes.string.isRequired,
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  subtitle: PropTypes.string.isRequired,
  listItem: PropTypes.array.isRequired,
};
