import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

const Contact = ({ data }) => (
  <Container className="contact">
    <Row className="justify-content-md-center">
      <Col sml={12} md={8} className="text-center">
        <h2>We can tailor document layups to suit your budget. Get in touch for a quick quote today.</h2>
        <div className="btn-container center">
          <Button variant="outline-primary" href="m&#97;ilt&#111;&#58;%74&#104;e%6Ci&#116;&#116;le&#108;a%79up&#64;%67m%61&#105;&#108;&#46;c%6F&#37;6D">
            Contact Us
          </Button>
          <Img
            alt="Button Shadow"
            fixed={data.buttonShadowImg.childImageSharp.fixed}
            className="btn-shadow"
          />
        </div>
        <p className="text-center">thelittlelayup[at]gmail[dot]com</p>
      </Col>
    </Row>
  </Container>
);

export default props => (
  <StaticQuery
    query={graphql`
      query {
        buttonShadowImg: file(relativePath: { eq: "button-shadow.png" }) {
          childImageSharp {
            fixed(quality: 100, width: 167, height: 41) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `}
    render={data => <Contact data={data} />}
  />
);


