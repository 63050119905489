import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PricingCard from '../components/pricing-card';

const Pricing = () => (
  <div className="border-container">
    <Container className="pricing" id="pricing">
      <Row>
        <Col md={12}>
          <h2 className="text-center">Pricing</h2>
        </Col>
      </Row>
      <Row>
        <Col sm={12} md={12} lg={4}>
          <PricingCard
            title="Buy Template"
            price={[<span>From</span>,' $50 aud']}
            subtitle="All package files included"
            listItem={["Production Templates", "PPM Templates", "Pitch Templates", "TV Glossy Templates", "InDesign & Sketch files included", "Submit your own (coming soon)"]}
            buttonText="Buy Now"
            buttonHref="/shop"
          />
        </Col>
        <Col sm={12} md={12} lg={4}>
          <PricingCard
            title="Little Layup"
            price="$345 aud"
            subtitle="Per day"
            listItem={["Choose a template", "DA included", "Proofreading", "Image sourcing", "Fast turnaround", "Working files supplied"]}
            buttonText="Book Now"
            buttonHref="m&#97;ilt&#111;&#58;%74&#104;e%6Ci&#116;&#116;le&#108;a%79up&#64;%67m%61&#105;&#108;&#46;c%6F&#37;6D"
          />
        </Col>
        <Col sm={12} md={12} lg={4}>
          <PricingCard
            title="Custom Layup"
            price="$445 aud"
            subtitle="Per day, minimum 3 days"
            listItem={["Bespoke layup", "Designer & DA Included", "Proofreading", "Image sourcing", "Round the clock support", "Working files supplied"]}
            buttonText="Book Now"
            buttonHref="m&#97;ilt&#111;&#58;%74&#104;e%6Ci&#116;&#116;le&#108;a%79up&#64;%67m%61&#105;&#108;&#46;c%6F&#37;6D"
          />
        </Col>
      </Row>
    </Container>
  </div>
);

export default Pricing;
